<template>
  <div
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/ytjyDetailsBg.png') + ')',
      backgroundColor: headerBgColor,
    }"
    class="boxSty"
  >
    <van-nav-bar title="业务档案" left-arrow @click-left="goback()">
      <template #left>
        <van-icon name="arrow-left" :size="20" color="#fff" />
      </template>
    </van-nav-bar>

    <div class="deadInfo">
      <div class="head">
        <span>遗体接运</span>
      </div>
      <div class="info1">
        <div class="name">
          <van-field
            v-model="model.deceasedBasicInfo.deadName"
            :readonly="true"
            input-align="left"
          />
        </div>
        <!-- <div class="time">
          <van-field
            v-model="model.receiveTime"
            :readonly="true"
            input-align="left"
          />
        </div>
        <div class="address">
          <div style="flex: 1">
            <van-field
              v-model="model.address"
              :readonly="true"
              input-align="left"
            />
          </div>
          <div><van-icon name="location-o" /></div>
        </div> -->
      </div>
      <div class="info2">
        <van-field
          v-model="model.deceasedBasicInfo.statementStatus"
          label="结算状态"
          :readonly="true"
          input-align="right"
        >
          <template #input>
            <span v-show="model.deceasedBasicInfo.statementStatus == 0"
              >未结算</span
            >
            <span
              v-show="
                model.deceasedBasicInfo.statementStatus == 1 ||
                model.deceasedBasicInfo.statementStatus == 3
              "
              >已结算</span
            >
            <span v-show="model.deceasedBasicInfo.statementStatus == 2"
              >取消结算</span
            >
          </template>
        </van-field>
        <van-field
          v-model="model.deceasedBasicInfo.totalAmount"
          label="总消费"
          :readonly="true"
          input-align="right"
        >
          <template #input>
            ￥{{ model.deceasedBasicInfo.totalAmount }}
          </template>
        </van-field>
      </div>
    </div>
    <div class="deceasedInfo">
      <div class="head">
        <h1>逝者信息</h1>
      </div>
      <div class="content">
        <van-field
          v-model="model.deceasedBasicInfo.deadName"
          label="姓名"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.deadCardCode"
          label="身份证号"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.deadAge"
          label="年龄"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.deadGender"
          label="性别"
          :readonly="true"
          input-align="right"
        >
          <template #input>
            <span v-if="model.deceasedBasicInfo.deadGender == 0"
              >未知的性别</span
            >
            <span v-else-if="model.deceasedBasicInfo.deadGender == 1"
              >男性</span
            >
            <span v-else-if="model.deceasedBasicInfo.deadGender == 2"
              >女性</span
            >
            <span v-else-if="model.deceasedBasicInfo.deadGender == 9"
              >未说明的性别
            </span>
            <span v-else></span>
          </template>
        </van-field>
        <van-field
          v-model="model.deceasedBasicInfo.deadBirthTime"
          label="出生日期"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.deadDeathTime"
          label="死亡日期"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.handlerTime"
          label="录入时间"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.deadHomeCode"
          label="户籍"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.deadRemainsType"
          label="死亡类别"
          :readonly="true"
          input-align="right"
        >
          <template #input>
            <span v-if="model.deceasedBasicInfo.deadRemainsType == 1"
              >正常遗体</span
            >
            <span v-else-if="model.deceasedBasicInfo.deadRemainsType == 2"
              >非正常遗体</span
            >
            <span v-else-if="model.deceasedBasicInfo.deadRemainsType == 3"
              >特殊遗体</span
            >
            <span v-else></span>
          </template>
        </van-field>
        <van-field
          v-model="model.deceasedBasicInfo.deadArriveTime"
          label="到馆时间 "
          :readonly="true"
          input-align="right"
        />
      </div>
    </div>
    <div class="familyInfo">
      <div class="head">
        <h1>家属信息</h1>
      </div>
      <div class="content">
        <van-field
          v-model="model.deceasedBasicInfo.contactName"
          label="姓名"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.contactCardCode"
          label="身份证号"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.contactPhone"
          label="联系电话"
          :readonly="true"
          input-align="right"
        />
        <van-field
          v-model="model.deceasedBasicInfo.relation"
          label="与逝者关系"
          :readonly="true"
          input-align="right"
        >
          <template #input>
            <span>{{ relationship[model.deceasedBasicInfo.relation] }}</span>
          </template>
        </van-field>
      </div>
    </div>
    <div class="evidenceInfo">
      <div class="head">
        <h1>证明材料</h1>
      </div>
      <div class="imgBox">
        <div class="imgItem">
          <img :src="basurl + model.deceasedBasicInfo.deathCertFile" alt="" />
          <div class="imgText">死亡证明</div>
        </div>
        <div class="imgItem">
          <img
            :src="
              basurl + model.deceasedBasicInfo.deadPermanentResidenceBooklet
            "
            alt=""
          />
          <div class="imgText">户口本</div>
        </div>
        <div class="imgItem">
          <img :src="basurl + model.deceasedBasicInfo.powerBookUrl" alt="" />
          <div class="imgText">委托书</div>
        </div>
      </div>
    </div>
    <div class="businessProcess">
      <div class="head">
        <h1>业务流程</h1>
      </div>
      <div class="process">
        <div
          class="processFlex"
          v-for="(item, index) in serviceStatusItems"
          :key="index"
        >
          <div class="Icons">
            <template v-if="item.serviceType">
              <img
                v-show="item.serviceType == 1 || item.serviceType == 3"
                class="bigIcon"
                :src="icon01"
                alt=""
              />
              <img
                v-show="item.serviceType == 2 || item.serviceType == 5"
                class="bigIcon"
                :src="icon02"
                alt=""
              />
              <img
                v-show="item.serviceType == 6"
                class="bigIcon"
                :src="icon03"
                alt=""
              />
              <img
                v-show="
                  item.serviceType == 4 ||
                  item.serviceType == 7 ||
                  item.serviceType == 8
                "
                class="bigIcon"
                :src="icon04"
                alt=""
              />
            </template>
            <template v-if="item.finishStatus">
              <img
                v-show="item.finishStatus == 1"
                class="smallIcon"
                :src="iconAlready"
                alt=""
              />
              <img
                v-show="item.finishStatus == 0"
                class="smallIcon"
                :src="iconNot"
                alt=""
              />
              <img
                v-show="item.finishStatus == 2"
                class="smallIcon"
                :src="iconWait"
                alt=""
              />
            </template>
          </div>
          <div class="content">
            <div class="contentTop">{{ item.serviceName }}</div>
            <div class="contentBottom">
              <div class="name">操作员：{{ item.staffName }}</div>
              <div class="timer">{{ item.finishTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="businessInfo">
      <van-tabs v-model="active">
        <van-tab
          :name="key"
          v-for="(value, key, index) in serviceCostMap"
          :key="index"
        >
          <template #title>
            <span v-if="key == 1">接运</span>
            <span v-else-if="key == 2">防腐整容</span>
            <span v-else-if="key == 3">遗体外运</span>
            <span v-else-if="key == 4">冷藏</span>
            <span v-else-if="key == 5">告别</span>
            <span v-else-if="key == 6">火化</span>
            <span v-else-if="key == 7">寄存</span>
            <span v-else-if="key == 8">殡葬用品</span>
          </template>
          <div class="businessSty">
            <div class="head">
              <h1>业务信息</h1>
            </div>
            <div class="content" v-for="(item, index) in value" :key="index">
              <van-field
                :value="index + 1"
                label="序号"
                :readonly="true"
                input-align="right"
              >
                <template #input>
                  {{ index + 1 }}
                </template>
              </van-field>
              <van-field
                :value="item.chargeName"
                label="收费名称"
                :readonly="true"
                input-align="right"
              />
              <van-field
                :value="item.priceUnit"
                label="价格单位"
                :readonly="true"
                input-align="right"
              >
                <template #input>
                  <span v-if="item.priceUnit == '01'">次</span>
                  <span v-else-if="item.priceUnit == '02'">个</span>
                  <span v-else></span>
                </template>
              </van-field>
              <van-field
                :value="item.salesPrice"
                label="销售单价"
                :readonly="true"
                input-align="right"
              >
                <template #input> ￥{{ item.salesPrice }} </template>
              </van-field>
              <van-field
                :value="item.salesNum"
                label="销售数量"
                :readonly="true"
                input-align="right"
              />
              <van-field
                :value="item.totalAmount"
                label="销售总价"
                :readonly="true"
                input-align="right"
              >
                <template #input> ￥{{ item.totalAmount }} </template>
              </van-field>
              <van-field
                :value="item.type"
                label="收费类型"
                :readonly="true"
                input-align="right"
              >
                <template #input>
                  <span v-if="item.type == '01'">政府定价</span>
                  <span v-else-if="item.type == '02'">政府指导价</span>
                  <span v-else-if="item.type == '03'">政府调节价</span>
                  <span v-else-if="item.type == '99'">其他</span>
                  <span v-else></span>
                </template>
              </van-field>
              <van-field
                :value="item.item"
                label="服务收费类型"
                :readonly="true"
                input-align="right"
              >
                <template #input>
                  <span v-if="item.item == '01'">基本服务</span>
                  <span v-else-if="item.item == '02'">
                    选择性服务(延伸服务)</span
                  >
                  <span v-else></span>
                </template>
              </van-field>
              <!-- <van-field
                :value="item.note"
                label="备注"
                :readonly="true"
                input-align="right"
              /> -->
            </div>
          </div>
        </van-tab>
        <!-- <van-tab title="遗体告别" name="2"></van-tab>
        <van-tab title="遗体火化" name="3"></van-tab>
        <van-tab title="遗体外运" name="4">
          <businessInfo />
        </van-tab>
        <van-tab title="遗体火化" name="5"></van-tab>
        <van-tab title="遗体火化" name="6"></van-tab>
        <van-tab title="遗体火化" name="7"></van-tab> -->
      </van-tabs>
    </div>
  </div>
</template>

<script>
import headerBg from "@/assets/images/ytjyDetailsBg.png";
import iconBusinessFile from "@/assets/images/icon-businessFile.png";
import icon01 from "@/assets/images/remainPickUp/icon-01.png";
import icon02 from "@/assets/images/remainPickUp/icon-02.png";
import icon03 from "@/assets/images/remainPickUp/icon-03.png";
import icon04 from "@/assets/images/remainPickUp/icon-04.png";
import iconAlready from "@/assets/images/remainPickUp/icon-already.png";
import iconNot from "@/assets/images/remainPickUp/icon-not.png";
import iconWait from "@/assets/images/remainPickUp/icon-wait.png";

import testImg from "@/assets/images/screenshot-4.png";
import testImg2 from "@/assets/images/screenshot-4.png";
import { showFailToast } from "vant";
import { ReceiveFile } from "@/api/remainsPickUp";
import {getDicts} from '@/api/common.js';
export default {
  // 业务档案
  name: "businessFile",

  data() {
    return {
      basurl: process.env.VUE_APP_API_BASE_URL + "/sys/common/static/",
      testImg,
      testImg2,
      headerBg,
      iconBusinessFile,
      icon01,
      icon02,
      icon03,
      icon04,
      iconAlready,
      iconNot,
      iconWait,
      headerBgColor: "#f3f4f6",
      model: {
        allServiceCostDetail: {},
        corpseVehicleDispatch: {},
        corpseVehicleSend: {},
        deceasedBasicInfo: {},
        serviceCostDetail: {},
        serviceHandlerStatus: [],
        serviceInfo: {},
      },
      serviceStatusItems: [], //业务流程
      tabs: [], //标签栏数量list
      serviceCostMap: {}, //业务信息
      active: "1",
      deadId: "", //业务标识 调接口需要
      relationship:{}
    };
  },
  created() {
    this.deadId = this.$route.query.deadId;
    this.getfileInfo();
    this.getRelationDict()
  },
  methods: {
    // 获取与逝者关系数据字典
    getRelationDict(){
      getDicts('relation').then(res=>{
        res.map((item)=>{
          this.relationship[item.value] = item.text;
        })
      })
    },
    goback() {
      // 返回上一级
      this.$router.go(-1);
    },
    getfileInfo() {
      ReceiveFile(this.deadId)
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            // res.data.serviceStatusItems   业务流程 array
            // res.data.serviceCostMap  业务信息 object
            // res.data 基本信息
            this.model = res.data;
            this.serviceStatusItems.push(...res.data.serviceHandlerStatus);
            this.serviceCostMap = res.data.allServiceCostDetail;
            // console.log(this.serviceCostMap);

            // console.log("this.model>>", this.model);
            // console.log("res>>>",res);
          } else {
            showFailToast(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="less">
.businessSty {
  .head {
    width: 100%;
    font-size: 0.25rem;
    padding: 0.08rem 0.42667rem;
  }
  .content {
    margin-bottom: 0.5rem;
  }
}
::v-deep .van-hairline--bottom::after {
  border: none;
}
::v-deep .van-nav-bar__title {
  color: #fff;
}
::v-deep .van-cell {
  padding: 0.08rem 0.42667rem;
}
::v-deep .van-cell::after {
  border: none;
}
::v-deep .van-nav-bar {
  background: none;
}
.boxSty {
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 0.6rem;
  overflow-x: hidden;
}
.deadInfo {
  width: 95%;
  background-color: #fff;
  margin: 0.5rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  padding: 0 0 2% 0;
  .head {
    text-align: center;
    height: 1rem;
    span {
      font-size: 0.4rem;
      background-color: #78bca5;
      display: block;
      width: 30%;
      margin: 0 auto;
      color: #fff;
      height: 100%;
      text-align: center;
      line-height: 1rem;
      border-radius: 0 0 0.6rem 0.6rem;
    }
  }
  .info1 {
    border-bottom: 1px solid #eeeeee;

    .name {
      ::v-deep .van-field__control {
        font-weight: bold;
        font-size: 0.7rem;
      }
    }
    .time {
      ::v-deep .van-field__control {
        font-weight: bold;
      }
    }
    .address {
      display: flex;
      justify-content: space-between;

      ::v-deep .van-field__control {
        font-weight: bold;
      }
    }
    ::v-deep .van-icon {
      color: #898989;
      font-size: 0.5rem;
      margin-right: 0.5rem;
    }
  }
}
.deceasedInfo,
.familyInfo,
.evidenceInfo,
.businessProcess,
.businessInfo {
  width: 95%;
  background-color: #fff;
  margin: 0.3rem auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  padding: 1% 0 2% 0;
  .head {
    width: 100%;
    font-size: 0.25rem;
    padding: 0.08rem 0.42667rem;
  }
}
.evidenceInfo {
  .imgBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.08rem 0.42667rem;
    box-sizing: border-box;

    .imgItem {
      width: 31%;
      height: 2.2rem;
      img {
        width: 100%;
        height: 100%;
      }
      .imgText {
        font-size: 0.3rem;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        position: relative;
        text-align: center;
        height: 0.5rem;
        top: -0.7rem;
        width: 100%;
        line-height: 0.5rem;
      }
    }
  }
}
.businessProcess {
  .process {
    width: 100%;
    padding: 0.08rem 0.42667rem;
    box-sizing: border-box;
    .processFlex:last-child {
      .Icons::after {
        height: 0;
        width: 0;
      }
    }
    .processFlex {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 2.4rem;
      box-sizing: border-box;

      .Icons {
        width: 1.8rem;
        height: 1.5rem;
        position: relative;

        .bigIcon {
          width: 80%;
          display: block;
          margin: auto;
        }
        .smallIcon {
          position: relative;
          top: -50%;
          left: 55%;
          width: 40%;
        }
      }
      .Icons::after {
        content: "";
        height: 2rem;
        background: #bbbbbb;
        width: 1px;
        position: absolute;
        left: 50%;
      }

      .content {
        font-size: 0.3rem;
        flex: 1;
        .contentTop {
          margin-bottom: 0.3rem;
        }
        .contentBottom {
          color: #898989;
          display: flex;
          justify-content: space-between;
          align-items: self-end;
        }
      }
    }
  }
}
</style>